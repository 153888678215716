<template>
    <div class="page_main">

        <dv-decoration-5 style="position: absolute;width: 1000px;height: 40px;top: 80px;left: 468px;"/>
        <dv-border-box-8>

            <div v-if="isShow" class="flex-col section2 space-y-44">
                <span class="self-start font_1 text">{{ dialogTitle }}</span>
                <div @click="Show(false)"
                     style="width: 60px;height: 30px;position: absolute;right: 10px;top: -25px;"></div>
                <div v-if="dialogTitle === '安全监测'" class="flex-col space-y-20">
                    <div class="flex-row items-center space-x-31">
                        <div class="flex-col justify-start items-start shrink-0 text-wrapper">
                            <span class="font_1 text_2">负载均衡</span>
                        </div>
                        <span class="font_2">&gt;</span>
                    </div>
                    <div class="flex-row items-center space-x-31">
                        <div class="flex-col justify-start items-start shrink-0 text-wrapper">
                            <span class="font_1 text_3">行为管理</span>
                        </div>
                        <span class="font_2">&gt;</span>
                    </div>
                    <div class="flex-row items-center space-x-31">
                        <div class="flex-col justify-start items-start shrink-0 text-wrapper">
                            <span class="font_1 text_4">机房运行</span>
                        </div>
                        <span class="font_2">&gt;</span>
                    </div>
                    <div class="flex-row items-center space-x-31">
                        <div class="flex-col justify-start items-start shrink-0 text-wrapper">
                            <span class="font_1 text_5">出口防火墙</span>
                        </div>
                        <span class="font_2">&gt;</span>
                    </div>
                </div>

                <div v-if="dialogTitle === '智慧营销客户端'" style="width: 327px;height: 299px">
                    <img class="self-center" style="width: 211px;height: 181px;margin-left: 25px"
                         src="../assets/c746e762377ac9b03d06da3a56ec6ad7.png"/>
                </div>
            </div>
            <div id="clock">
                <p class="date">{{ date }}</p>
                <p class="time">{{ time }}</p>
            </div>
            <div class="flex-col page space-y-17">
                <div class="flex-row items-start">
                    <div style="visibility: hidden" class="flex-col  group space-y-6">
                        <div class="flex-col  justify-start items-center image-wrapper" ref="imageWrapper"
                             @click="rotate">
                            <img class="image wrapper" src="../assets/17cdfeb1066b0effe35923c4583caea6.png"/>
                        </div>
                        <span class="font_1">视频监控</span>
                    </div>
                    <div @click="See('http://web.widget.daqsoft.com/deploy/view/index.html?userKey=B6FF3-B32DF-75331-9D827-27CD3&pid=1589885030941982720&sid=1589885251604316160&id=1589885251684007937')"
                         class="flex-col  group_3 space-y-12">
                        <div class="flex-col justify-start items-center image-wrapper_3">
                            <img class="image_4 wrapper" src="../assets/8cb89c9c1a15eb1ddddb380e5efdc35a.png"/>
                        </div>
                        <span class="self-center font_1">数据看板</span>
                    </div>
                    <div @click="See('http://39.153.247.101:10098/real-time-operation-monitor/login?key=be197ad05d3b33d1b9ea6f682a291cfcdfe0900cd2fb45bc11ea063b3f0bf73d')"
                         class="flex-col  group_5 view">
                        <div class="flex-col justify-start items-center relative group_6">
                            <div class="section"></div>
                            <img class="image_5 pos" src="../assets/f2e24b0fd15c77c0db94f8ab0fbf247a.png"/>
                            <img class="image_6 pos_2" src="../assets/6baba432bb8cf4714a146ed399953a96.png"/>
                            <img class="image_6 pos_3" src="../assets/80a95102e2ffdbdb1c21d457a55fb456.png"/>
                            <img class="image_13 pos_13" src="../assets/246dbbe6164efc30fcd68e9417878aa0.png"/>
                            <div class="flex-col justify-start items-center image-wrapper_5 pos_11">
                                <img class="image_12 wrapper" src="../assets/ecc6726f9484b56ef682c8b1c50aabf9.png"/>
                            </div>
                            <img class="image_9 pos_7" src="../assets/9da3555d8a5fcd859d0586a315ca0dc3.png"/>
                            <img class="image_10 pos_9" src="../assets/ded50b49b3f829c415997b72cd9e5a68.png"/>
                        </div>
                        <img class="shrink-0 self-center image_14"
                             src="../assets/420c54efd120f430524e06fe7b9fac93.png"/>
                        <span class="self-center font_1 text_2">景区运行监测</span>
                    </div>
                    <div @click="Show(true,'安全监测')" class="flex-col group_5 view_2">
                        <div class="flex-col justify-start items-start relative group_6">
                            <img class="image_8" src="../assets/9c1589c735a63fbd04f26c12ddc05046.png"/>
                            <img class="image_5 pos_4" src="../assets/1882ccbff17ca51aa1eb75b3fdede2ac.png"/>
                            <img class="image_6 pos_6" src="../assets/577224e3d83107bc93ee64039fbd9265.png"/>
                            <img class="image_7 pos_5" src="../assets/a98bcae22cddde943709b9aad87a7b66.png"/>
                            <img class="image_13 pos_14" src="../assets/1bcce277c9d775cd9ff87a990919ee70.png"/>
                            <div class="flex-col justify-start items-center image-wrapper_6 pos_12">
                                <img class="image_11 wrapper" src="../assets/b4dcff5c1e2f3c8e717483383c7cc44e.png"/>
                            </div>
                            <img class="image_9 pos_8" src="../assets/a0a85fc956d222c3b2b072348f3a9bfe.png"/>
                            <img class="image_10 pos_10" src="../assets/53407cebe49147a773f31343121e8f29.png"/>
                        </div>
                        <img class="shrink-0 self-center image_14"
                             src="../assets/adb6071c20c10fb56af2d7cbab9be50a.png"/>
                        <span class="self-center font_1 text">安全监测</span>
                    </div>
                    <div @click="See('http://39.153.247.101:10098/command-dispatch/login?key=be197ad05d3b33d1b9ea6f682a291cfcdfe0900cd2fb45bc11ea063b3f0bf73d')"
                         class="flex-col  group_4 space-y-14">
                        <div class="flex-col justify-start items-center image-wrapper_4">
                            <img class="image_3 wrapper" src="../assets/5a71275bac171553bbdde1bbdb26879c.png"/>
                        </div>
                        <span class="self-center font_1">指挥调度</span>
                    </div>
                    <div style="visibility: hidden" class="flex-col  group_2 space-y-12">
                        <div class="flex-col justify-start items-center image-wrapper_2">
                            <img class="image_2 wrapper" src="../assets/c9a048c516ad43322e9ff71c685ea8b5.png"/>
                        </div>
                        <span class="font_1">舆情系统</span>
                    </div>
                    <div @click="Show(true,'智慧营销客户端')"
                         class="flex-col justify-start items-start image-wrapper_7">
                        <img class="image_16 wrapper" src="../assets/3a3099eba249571c45bfeff6116869af.png"/>
                    </div>
                    <div @click="See('https://clmerchant.yueyat.net:8083')"
                         class="flex-col  justify-start items-center image-wrapper_8">
                        <img class="image_15 wrapper" src="../assets/f13813204986395862fcd9fe24358fa2.png"/>
                    </div>
                </div>
                <div style="position: absolute;left: 800px;top: 580px; z-index: 999;width: 310px;height: 130px;"
                     @click="See('http://39.153.247.101:10098/scenic/index#eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJkcV9zYW1jX3Y1IiwianRpIjoiM2M4ODg4YmQtMGIzMy00MTUzLWJiZWUtNjJkODg5MWQwZmUzIiwic3ViIjoiZGNmNThjNWUzNjMyNTM1OWRhYWE0MmRiYjNjYzhiMDFhMWRlZDMzOTk3N2UxMzEzZmYwMmI0MWU5OGE0Y2EyZSIsImF1ZCI6ImRjZjU4YzVlMzYzMjUzNTlkYWFhNDJkYmIzY2M4YjAxYTFkZWQzMzk5NzdlMTMxM2ZmMDJiNDFlOThhNGNhMmUiLCJpYXQiOjE2ODQ5MTA0NDMsImV4cCI6MTY4NDk5Njg0Mywic2NvcGUiOlsiQUNDRVNTX1RPS0VOIl19.0z28GzGZUKhYuAe0OJCUVlM-2JnjYnUZyAb1dJG17RybDavD1Lcidev_J8RNpJ3n-lJ04c3Gg3xdshQbK9Qaww')"></div>
                <div class="flex-row justify-between self-center group_7">
                    <span class="font_1 text_3">智慧营销客户端</span>
                    <span class="font_1" style="position: absolute;bottom: 18px;right: 610px;">智慧营销管理端</span>
                </div>
            </div>

        </dv-border-box-8>
    </div>
</template>

<script>

import {isShallow} from "vue";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Page",
    data() {
        return {
            time: '',
            date: '',
            isShow: false,
            Showr: false,
            dialogTitle: null,
        }
    },
    mounted() {
        this.getTime()
    },
    methods: {
        See(e) {
            window.open(e, '_blank')
        },
        Show(isShow, text) {
            this.isShow = isShow
            this.dialogTitle = text
            console.log(this.dialogTitle)
        },
        isShallow,
        rotate() {
            this.$refs.imageWrapper.classList.add('rotate-animation');
            setTimeout(() => {
                this.$refs.imageWrapper.classList.remove('rotate-animation');
            }, 1000);
        },
        getTime() {
            this.week = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            this.timerID = setInterval(this.updateTime, 1000);
            this.updateTime()
        },
        updateTime() {
            var cd = new Date();
            this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2);
            this.date = this.zeroPadding(cd.getFullYear(), 4) + '-' + this.zeroPadding(cd.getMonth() + 1, 2) + '-' + this.zeroPadding(cd.getDate(), 2) + ' ' + this.week[cd.getDay()];
        },
        zeroPadding(num, digit) {
            var zero = '';
            for (var i = 0; i < digit; i++) {
                zero += '0';
            }
            return (zero + num).slice(-digit);
        }
    }

}
</script>

<style scoped>
::-webkit-scrollbar {
    width: 0 !important;
}

::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
}

body, html {
    margin: 0;
    padding: 0;
    overflow: hidden;
}


.space-y-44 {
    & > *:not(:first-child) {
        margin-top: 44px;
    }
}

.section2 {
    bottom: 200px;
    left: 900px;
    position: absolute;
    padding: 19px 10px 29px 30px;
    background-image: url('../assets/52cd6d15b4e65796e2410671bde5db00.png');
    background-repeat: no-repeat;

    .font_1 {
        font-size: 20px;
        font-family: SourceHanSansCN;
        line-height: 20px;
        font-weight: 500;
        color: #e6da64;
    }

    .text {
        color: #ffffff;
    }

    .space-y-20 {
        & > *:not(:first-child) {
            margin-top: 20px;
        }

        .space-x-31 {
            & > *:not(:first-child) {
                margin-left: 31px;
            }

            .text-wrapper {
                padding: 6px 0;
                background-image: url('../assets/0a3f75e964730d0bd6afab6218ae9c6e.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                width: 229px;
                height: 32px;

                .text_2 {
                    margin-top: 3px;
                    margin-left: 19px;
                }

                .text_3 {
                    margin-left: 18px;
                    line-height: 21px;
                }

                .text_4 {
                    margin-left: 18px;
                    line-height: 21px;
                }

                .text_5 {
                    margin-left: 20px;
                }
            }

            .font_2 {
                font-size: 30px;
                font-family: SourceHanSansCN;
                line-height: 15px;
                font-weight: 500;
                color: #e6da64;
            }
        }
    }
}

.space-y-44 {
    & > *:not(:first-child) {
        margin-top: 44px;
    }
}

.wrapper {
    animation: bounce 1s infinite;
}

.wrapper:hover {
    animation: none;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotate-animation {
    animation: rotate 1s linear;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-9px);
    }
}

.page_main {
    background-image: url('../assets/bg.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.page {
    padding: 426px 0 0 118px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.space-y-17 > *:not(:first-child) {
    margin-top: 17px;
}

.group {
    width: 104px;
}

.space-y-6 > *:not(:first-child) {
    margin-top: 6px;
}

.lizi {
    position: absolute;
}

.image-wrapper {
    margin-right: 3px;
    padding: 30px 0;
    background-image: url('../assets/95b7e68217d3e34666294679eede2ab0.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.image {
    width: 60px;
    height: 45px;
}

.font_1 {
    font-size: 24px;
    font-family: SourceHanSansCN;
    letter-spacing: 1.5px;
    line-height: 24px;
    font-weight: 500;
    color: #58daff;
}

.group_3 {
    margin-left: 19px;
    margin-top: 83px;
    width: 134px;
}

.image-wrapper_3 {
    padding: 42px 0 35px;
    background-image: url('../assets/4178262084211eeef0d8eafbab207472.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.image_4 {
    width: 59px;
    height: 59px;
}

.group_5 {
    width: 176px;
}

.view {
    margin-left: 15px;
    margin-top: 200px;
}

.group_6 {
    padding-bottom: 39px;
}

.section {
    opacity: 0.83;
    background-image: linear-gradient(180deg, #1454a7 0%, #0c408b 60%, #042d6f 100%);
    width: 143px;
    height: 134px;
}

.image_5 {
    width: 161px;
    height: 22px;
}

.pos {
    position: absolute;
    right: 0;
    top: 0;
}

.image_6 {
    width: 17px;
    height: 173px;
}

.pos_2 {
    position: absolute;
    left: 15px;
    bottom: 0;
}

.pos_3 {
    position: absolute;
    right: 0;
    bottom: 0;
}

.image_13 {
    width: 161px;
    height: 40px;
}

.pos_13 {
    position: absolute;
    right: 0;
    bottom: 0;
}

p {
    margin: 0;
    padding: 0;
}

#clock {
    font-family: 'Share Tech Mono', monospace;
    color: #ffffff;
    text-align: center;
    position: absolute;
    color: #daf6ff;
    text-shadow: 0 0 20px #0aafe6, 0 0 20px rgba(10, 175, 230, 0);
}

#clock .time {
    letter-spacing: 0.05em;
    font-size: 30px;
    position: absolute;
    top: 30px;
    left: 1740px;
}

#clock .date {
    letter-spacing: 0.1em;
    font-size: 30px;
    position: absolute;
    width: 350px;
    top: 30px;
    left: 30px;
}

#clock .text {

    letter-spacing: 0.1em;
    font-size: 12px;
    padding: 20px 0 0;
}

.image-wrapper_5 {
    padding: 32px 0 36px;
    background-image: url('../assets/a847765427964b99e0402a48d69b63cd.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 136px;
}

.pos_11 {
    position: absolute;
    left: 50%;
    top: 31px;
    transform: translateX(-50%);
}

.image_12 {
    width: 76px;
    height: 65px;
}

.image_9 {
    width: 141px;
    height: 13px;
}

.pos_7 {
    position: absolute;
    right: 10px;
    top: 6px;
}

.image_10 {
    width: 136px;
    height: 13px;
}

.pos_9 {
    position: absolute;
    left: 0;
    top: 15px;
}

.image_14 {
    margin-top: -7px;
    width: 16px;
    height: 16px;
}

.text_2 {
    margin-top: 11px;
}

.view_2 {
    margin-left: 791px;
    margin-top: 200px;
}

.image_8 {
    width: 143px;
    height: 133px;
}

.pos_4 {
    position: absolute;
    left: 0;
    top: 0;
}

.pos_6 {
    position: absolute;
    right: 16px;
    bottom: 0;
}

.image_7 {
    width: 18px;
    height: 173px;
}

.pos_5 {
    position: absolute;
    left: 0;
    bottom: 0;
}

.pos_14 {
    position: absolute;
    left: 0;
    bottom: 0;
}

.image-wrapper_6 {
    padding: 25px 0 34px;
    background-image: url('../assets/641724a4e824be481ad25413ea93c8e8.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 136px;
}

.pos_12 {
    position: absolute;
    left: 50%;
    top: 31px;
    transform: translateX(-50%);
}

.image_11 {
    width: 71px;
    height: 74px;
}

.pos_8 {
    position: absolute;
    left: 9px;
    top: 6px;
}

.pos_10 {
    position: absolute;
    right: 0;
    top: 15px;
}

.text {
    margin-top: 9px;
    line-height: 23px;
}

.group_4 {
    margin-left: 14px;
    margin-top: 83px;
    width: 135px;
}

.space-y-14 > *:not(:first-child) {
    margin-top: 14px;
}

.image-wrapper_4 {
    padding: 39px 0 33px;
    background-image: url('../assets/bd76c64cfb9e258d05e7243a5bfd2ae9.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.image_3 {
    width: 65px;
    height: 64px;
}

.group_2 {
    margin-left: 18px;
    width: 105px;
}

.space-y-12 > *:not(:first-child) {
    margin-top: 12px;
}

.image-wrapper_2 {
    margin-left: 4px;
    padding: 31px 0 28px;
    background-image: url('../assets/c01ce1ee6c5561ed0f7b601e7e5c570f.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.image_2 {
    width: 45px;
    height: 45px;
}

.image-wrapper_7 {
    margin-left: -1227px;
    margin-top: 353px;
    padding: 90px 0 49px;
    background-image: url('../assets/2a04bd6f52d704f0d940a02bd233e501.png');
    background-position: 0% 0%;
    background-size: 473px 242px;
    background-repeat: no-repeat;
    width: 473px;
    height: 242px;
}

.image_16 {
    margin-left: 76px;
    width: 119px;
    height: 103px;
}

.image-wrapper_8 {
    margin-left: 49px;
    margin-top: 354px;
    padding: 70px 0 50px;
    background-image: url('../assets/106495eb273c0e554d9d16f375869f26.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 245px;
    height: 241px;
}

.image_15 {
    width: 121px;
    height: 121px;
}

.group_7 {
    width: 818px;
}

.text_3 {
    line-height: 23px;
}
</style>
